<template>
  <div class="page-navigation">
    <div class="page-navigation__wrapper">

    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNavigation',
};
</script>
